.public-view-report.framed {
	margin: 0 !important;
	padding: 0;
	width: 100%;
	max-width: 100%;
}

.public-view-report .company-name {
	font-size: 20px;
	margin-bottom: 5px;
}

.public-view-report .keyword {
	font-size: 16px;
	margin-bottom: 0px;
}

.public-view-report .created-date {
	font-size: 16px;
	margin-bottom: 15px;
}

.public-view-report .public-grid-map-container {
	height: calc(100vh - 135px);
}

.public-view-report.framed .public-grid-map-container {
	height: 100vh;
}
