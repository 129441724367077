@media screen and (max-width: 767px) {
	.payments-list-container {
		height: calc(100vh - 110px);
	}

	.payments-list-container .main-content-inner-container {
		height: calc(100vh - 150px);
	}

	.main-content-inner-container table td.payment-campaign-name {
		max-width: 40vw;
	}
}
