.mapboost-report-v5 {
	text-align: center;
	padding: 12px;
}

.mapboost-report-v5 .report-header-container {
	position: sticky;
	top: 0;
	left: 0;
	padding-bottom: 1px;
	background-color: #ffffff;
	z-index: 98;
}

.mapboost-report-v5 .report-keyword-container {
	margin-bottom: 10px;
	background-color: #f9f9f9;
	border: 1px solid #ced4da;
	border-radius: 5px;
	padding: 10px 15px;
}

.mapboost-report-v5 .report-header .display-5 {
	font-size: 2rem;
	font-weight: 500;
	font-size: 20px;
}

.mapboost-report-v5 .report-header .text-muted {
	font-size: 16px;
}

.mapboost-report-v5 .report-header .report-header-right {
	text-align: right;
}

.mapboost-report-v5 .nav .nav-item {
	margin: 3px 5px 3px 0;
	border: 1px solid #ced4da;
	border-radius: 5px;
}

.mapboost-report-v5 .rank-report .rank-table thead {
	top: 160px;
}

.mapboost-report-v5 .report-body {
	text-align: left;
}

.mapboost-report-v5 .report-body .grid-report-keywords {
	top: 106px;
}

.mapboost-report-v5 .report-body .spinner-border {
	display: block;
	margin: 20px auto;
}

.mapboost-report-v5 .ranking-filters {
	margin-top: 30px;
}

.mapboost-report-v5 .campaign-reports {
	background-color: #f9f9f9;
	border: 1px solid #ced4da;
	border-radius: 5px;
	padding: 15px;
}

.mapboost-report-v5 .canceled-campaign-warning {
	padding: 40px;
	font-size: 28px;
	font-weight: bold;
	color: #e91717;
}

.mapboost-report-v5 .no-reports-notice {
	padding: 20px;
	font-size: 20px;
}

.mapboost-report-v5 .campaign-reports .flex-fill {
	text-align: left;
}

.mapboost-report-v5 .report-headline-links-wrapper {
	margin: 3px 0;
	text-align: end;
}

.mapboost-report-v5 .report-headline-links-wrapper .report-headline-link {
	font-size: 18px;
	background-color: #dbdbdb;
	color: #505050;
	border-radius: 5px;
	margin-right: 8px;
	padding: 3px 8px;
	min-width: 35px;
}

.mapboost-report-v5 .report-headline-links-wrapper .report-headline-link:last-child {
	margin-right: 0;
}

.mapboost-report-v5 .report-headline-links-wrapper .report-headline-link i {
	margin-right: 0;
}

.report-headline-link-separator {
	font-size: 26px;
	vertical-align: middle;
	padding: 0 6px 0 0;
}

.mapboost-report-v5 .one-line-input-label {
	min-width: 150px;
}

.mapboost-report-v5 .map-reloading-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.mapboost-report-v5 .map-reloading-bg {
	background-color: #ffffff;
	opacity: 0.5;
}

.mapboost-report-v5 .keyword-map-stats-v5 .keyword-stat-item {
	background-color: #ffffff;
	border: 1px solid #ced4da;
	border-radius: 5px;
}

@media screen and (max-width: 767px) {
	.mapboost-report-v5 .report-header {
		flex-direction: column;
		align-items: stretch;
	}

	.mapboost-report-v5 .report-header .report-header-left {
		overflow: hidden;
	}

	.mapboost-report-v5 .report-header .report-header-right {
		/* display: none; */
		text-align: left;
	}

	.mapboost-report-v5 .rank-report .rank-table thead {
		top: 159px;
	}
}

@media screen and (max-width: 991px) {
	.mapboost-report-v5 .report-header {
		flex-direction: column;
		align-items: stretch;
		margin-bottom: 0;
	}

	.mapboost-report-v5 .report-header .report-header-left {
		overflow: hidden;
	}

	.mapboost-report-v5 .report-header .report-header-right {
		/* display: none; */
		text-align: left;
	}

	.mapboost-report-v5 .rank-report .rank-table thead {
		top: 110px;
	}

	.mapboost-report-v5 .campaign-reports {
		top: 125px;
	}

	.mapboost-report-v5 .report-body .grid-report-keywords {
		top: 190px;
	}
}
