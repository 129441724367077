:root {
	--nav-links-color: #343434;
}

.app-left-nav-links {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: calc(var(--default-margin) / 3);
	padding: calc(var(--default-margin) / 2);
	width: 100%;
	height: 100%;
	overflow: auto;
}

.app-left-nav-links::-webkit-scrollbar {
	width: 0;
}

.app-left-nav-links:hover::-webkit-scrollbar {
	width: 4px;
}

.app-left-nav-links .app-left-nav-link {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	position: relative;
	width: 100%;
	color: var(--default-font-color);
	text-decoration: none;
	/* transition: var(--default-transition); */
	text-align: left;
	padding: 5px 15px;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	min-height: 40px;
	border-radius: 6px;
	line-height: 1.57143;
	font-size: var(--default-font-size);
	font-family: var(--default-font-bold);
	font-weight: 900;
	text-transform: capitalize;
}

.app-left-nav-links .app-left-nav-link.active {
	color: #2d86cf;
	background-color: var(--alt-background-color);
}

.app-left-nav-links .app-left-nav-link:hover {
	background-color: rgba(150, 159, 172, 0.16);
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-icon {
	width: 16px;
	height: 16px;
	line-height: 16px;
	text-align: center;
	/* border-left: 5px solid transparent; */
	transition: var(--default-transition);
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-icon img {
	width: 25px;
	height: 25px;
	margin-left: 10px;
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-icon i {
	font-size: 20px;
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-text {
	position: relative;
	top: 3px;
	text-transform: capitalize;
	line-height: 30px;
	margin-left: 10px;
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-arrow {
	position: absolute;
	right: 0;
	font-size: 18px;
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-unread-messages {
	position: absolute;
	right: 20px;
	font-size: 12px;
	padding: 1px 5px 0px 5px;
	background-color: #ff0000;
	color: #ffffff;
}
