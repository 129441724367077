.offer-details-container {
	padding: 20px;
	font-family: "Poppins", sans-serif;
	font-weight: normal;
	color: #444444;
}

.offer-details-container .container {
	max-width: 700px;
	padding: 0;
}

.offer-details-container .offer-details-container-inner {
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border-radius: 5px;
	padding: 30px;
	/* height: calc(100vh - 30px); */
	box-shadow: 0px 0px 7px 0px #dddddd;
}

.offer-details-container .offer-details-container-inner .campaign-title-wrapper {
	padding-bottom: 40px;
}

.offer-details-container .offer-details-container-inner .campaign-title {
	font-weight: 700;
	font-size: 32px;
	color: #0b305b;
}

.offer-details-container .offer-details-container-inner p {
	font-size: 18px;
	font-weight: 400;
	font-size: 18px;
}

.offer-details-container .offer-details-container-inner a {
	color: #2b7bb9;
}

.offer-details-container .offer-details-container-inner .form-label {
	min-width: 150px;
}

.offer-details-container .offer-details-container-inner a.report-url-link {
	background-color: #4ab24a;
	border-color: #327a39;
	border: 1px solid #1f5a87;
	border-radius: 4px;
	width: 100%;
	padding: 10px 20px;
	font-size: 25px;
	color: #ffffff;
}

.offer-details-container .offer-details-container-inner .offer-title {
	font-family: "Cabin", sans-serif;
	font-weight: 700;
	font-size: 30px;
}

.offer-details-container .offer-details-container-inner .offer-payment-details-wrapper {
	padding: 20px;
	background-color: #85e86145;
	border: 1px solid #75b2704a;
	text-align: center;
}

.offer-details-container .offer-details-container-inner .offer-name {
	font-size: 24px;
	font-weight: bold;
}

.offer-details-container .offer-details-container-inner .offer-price {
	font-size: 24px;
}

.offer-details-container .offer-details-container-inner .offer-full-price {
	text-decoration: line-through;
}

.offer-details-container .offer-details-container-inner .offer-subscription {
	font-size: 18px;
}

.offer-expired-note {
	font-size: 24px;
	font-weight: normal;
	color: #cc0000;
	margin-top: 20px;
}

.offer-details-container .offer-details-container-inner .offer-discount-wrapper {
	color: #cc0000;
	font-size: 18px;
	font-weight: normal;
	margin-bottom: 10px;
}

.offer-details-container .offer-details-container-inner .offer-discount-amount {
	font-size: 36px;
	font-weight: bold;
	margin-bottom: 5px;
}

.offer-details-container .offer-details-container-inner .offer-card-wrapper {
	padding: 12px;
	margin: 20px 0;
	background-color: #ffffe9cc;
	border: 1px solid #dbdbc3;
	margin-bottom: 10px;
}

.offer-details-container .offer-details-container-inner .submit-button {
	width: 100%;
	background: #2fb74f;
	color: #ffffff;
	border: 1px solid #327a39;
	font-family: "Cabin", sans-serif;
	font-size: 30px;
	border-radius: 3px;
	padding: 10px;
}

.offer-details-container .offer-details-container-inner .offer-note {
	font-size: 14px;
	font-weight: 400;
	margin-top: 20px;
	text-align: center;
}

.offer-details-container .offer-details-container-inner .user-details-wrapper {
	padding-top: 20px;
}

@media screen and (min-width: 992px) {
	.offer-details-container .container {
		max-width: 700px;
	}
}

@media screen and (max-width: 768px) {
	.offer-details-container {
		padding: 10px;
		padding-bottom: 20px;
	}

	.offer-details-container .container {
		padding: 0;
	}

	.offer-details-container .offer-details-container-inner {
		padding: 20px;
	}

	.offer-details-container .offer-details-container-inner .campaign-title-wrapper {
		padding-bottom: 30px;
	}

	.offer-details-container .offer-details-container-inner .offer-title {
		font-size: 24px;
	}

	.offer-details-container .offer-details-container-inner .offer-name,
	.offer-details-container .offer-details-container-inner .offer-price {
		font-size: 20px;
	}

	.offer-details-container .offer-details-container-inner .offer-note {
		font-size: 16px;
	}

	.offer-details-container .offer-details-container-inner .form-label {
		font-size: 1rem;
		font-weight: 400;
		margin-bottom: 0;
	}
}
