.business-profile-container {
	height: calc(100vh - 40px);
}

.business-profile-container .main-content-inner-container {
	height: 100%;
	display: flex;
	/* align-items: center; */
}

.business-profile-connect-container {
	padding: 20px;
	width: 100%;
	max-width: 600px;
	text-align: center;
	margin: 0 auto;
	margin-top: 50px;
}

.business-profile-connect-container iframe {
	position: 'absolute';
	top: 0;
	left: 0;
	width: 100px;
	height: 100px;
}

.business-profile-connect-container .search-results-wrapper {
	height: 315px;
	overflow-y: auto;
}

.business-profile-connect-container .search-results-wrapper .search-results-item {
	cursor: pointer;
	text-align: start;
	padding: 7px 20px;
	font-size: 14px;
}

.business-profile-connect-container .search-results-wrapper .search-results-item:hover {
	background-color: #ececec;
}

.business-profile-connect-container .search-results-wrapper .search-results-item .name {
	font-size: 18px;
}

.business-profile-connect-container .search-results-wrapper .search-results-item .name .btn {
	margin-top: -3px;
}

@media screen and (max-width: 767px) {
	.business-profile-connect-container {
		margin-top: 0;
	}
}
