.ranking-filters-v5 {
	margin-top: 20px;
}

.ranking-filters-v5 .nav-pills {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
}

.ranking-filters-v5 .nav-pills .nav-item {
	flex: 1 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #f9f9f9;
	color: black;
	padding: 15px 20px;
	border-radius: 10px;
}

.ranking-filters-v5 .nav-pills .nav-item .nav-link {
	width: 100%;
	color: black;
	cursor: pointer;
	border-radius: 20px;
	font-size: 16px;
}

.ranking-filters-v5 .nav-pills .nav-item .nav-link.all {
	min-width: 80px;
}

.ranking-filters-v5 .nav-pills .nav-item.active {
	background-color: #ededed;
	border: 1px solid #d3d3d3;
	border-radius: 20px;
}

.ranking-filters-v5 .nav-pills .nav-item .ranking-results {
	font-size: 24px;
	font-weight: 600;
}

.ranking-filters-v5 .nav-pills .nav-item .ranking-stat-item {
	font-size: 20px;
	font-weight: 400;
}

.ranking-filters-v5 .nav-pills .nav-item .ranking-stat-item.good {
	color: #1ac049;
}

.ranking-filters-v5 .nav-pills .nav-item .ranking-stat-item.bad {
	color: #ff2b2b;
}

.ranking-filters-v5 .nav-pills .nav-item .ranking-stat-item.all {
	color: #1ac049;
}

.ranking-filters-v5 .nav-pills .nav-item .label {
	color: #58687c;
}

.flex-line-break {
	flex-basis: 100%;
	height: 0;
}
