.mapboost-report-v5 .ranking-data-container {
	padding: 0 10px;
	margin-bottom: 10px;
}

.mapboost-report-v5 .grid-ranking-filters-container {
	padding: 0 10px;
}

.mapboost-report-v5 .rank-report {
	margin-top: var(--gap);
	text-align: left;
}

.mapboost-report-v5 .rank-report .map-data-not-available {
	margin-top: 78px;
}

.mapboost-report-v5 .ranking-filters-v5 {
	margin-top: 0;
}

.mapboost-report-v5 .ranking-filters-v5 .nav-pills .nav-item {
	background-color: #ffffff;
	border: 1px solid #ced4da;
	border-radius: 5px;
}

.mapboost-report-v5 .ranking-filters-v5 .nav-pills .nav-item.active,
.mapboost-report-v5 .ranking-filters-v5 .nav-pills .nav-item:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mapboost-report-v5 .ranking-filters-v5 .nav-pills .nav-item:last-child {
	margin-right: 0;
}

.mapboost-report-v5 .grid-ranking-filters-container > div {
	padding-bottom: 30px;
	border-bottom: 1px solid #ced4da;
}

.mapboost-report-v5 .rank-report .rank-stats {
	margin-top: var(--gap);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: var(--gap);
}

.mapboost-report-v5 .rank-report .rank-table thead {
	position: sticky;
	top: 58px;
	background-color: #f9f9f9;
	z-index: 1;
}

.mapboost-report-v5 .rank-report .rank-table {
	vertical-align: middle;
	margin-bottom: 0;
}

.mapboost-report-v5 .rank-report .rank-table .rank-table-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mapboost-report-v5 .rank-report .rank-table th,
.mapboost-report-v5 .rank-report .rank-table td {
	border: 0;
}

.mapboost-report-v5 .rank-report .rank-table .rank {
	width: 10px;
	text-align: center;
	margin-right: 50px;
}

.mapboost-report-v5 .rank-report .rank-table .rank-details {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.mapboost-report-v5 .rank-report .rank-table .rank-details .rank-difference-value {
	display: inline-block;
	width: 30px;
	text-align: right;
}

.mapboost-report-v5 .rank-report .rank-table .rank-details .rank-difference.good {
	color: #1ac049;
	font-weight: 600;
}

.mapboost-report-v5 .rank-report .rank-table .rank-details .rank-difference.bad {
	color: #ff2b2b;
	font-weight: 600;
}

.mapboost-report-v5 .rank-report .rank-table .actions {
	text-align: right;
	width: 60px;
}

.mapboost-report-v5 .rank-report .rank-table .actions a {
	margin-right: 10px;
}

.mapboost-report-v5 .rank-report .rank-table .actions a:last-child {
	margin-right: 0;
}

.mapboost-report-v5 .rank-report .btn {
	text-align: left;
	border: 1px solid #ced4da;
	font-weight: 500;
}

.mapboost-report-v5 .rank-report .btn:hover {
	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.mapboost-report-v5 .rank-report .rank-table .rank-location-btn {
	display: flex;
	padding: 10px 20px;
}

.mapboost-report-v5 .rank-report .rank-table .rank-location-btn .rank-location {
	flex: auto;
}

.mapboost-report-v5 .rank-report .btn.btn-success {
	background-color: #ffffff;
	color: #32cc44;
}

.mapboost-report-v5 .rank-report .btn.btn-success:hover {
	background-color: #e6f5e8;
	border-color: #a9e4af;
}

.mapboost-report-v5 .rank-report .btn.btn-warning {
	background-color: #ffffff;
	color: #e0a500;
}

.mapboost-report-v5 .rank-report .btn.btn-warning:hover {
	background-color: #faf2de;
	border-color: #f8dc8d;
}

.mapboost-report-v5 .rank-report .btn.btn-danger {
	background-color: #ffffff;
	color: #f44336;
}

.mapboost-report-v5 .rank-report .btn.btn-danger:hover {
	background-color: #fdeceb;
	border-color: #fcbdb9;
}

.mapboost-report-v5 .rank-report .btn.btn-secondary {
	background-color: #ffffff;
	color: #707070;
}

@media screen and (max-width: 768px) {
	.mapboost-report-v5 .rank-report .rank-stats {
		flex-direction: column;
		align-items: stretch;
		gap: 10px;
	}

	.mapboost-report-v5 .rank-report .rank-table {
		font-size: 14px;
	}
}

@media screen and (max-width: 991px) {
	.mapboost-report-v5 .rank-report {
		margin-top: 10px;
	}

	.mapboost-report-v5 .rank-report .map-data-not-available {
		margin-top: 0px;
	}

	.mapboost-report-v5 .rank-report .rank-table .rank {
		margin-right: 25px;
	}
}
