.page-header-container {
	display: flex;
	flex-direction: row;
	padding: calc(var(--gap) / 2) 0;
	padding-bottom: 0;
	justify-content: flex-start;
	align-items: center;
}

.headline-wrapper {
	display: flex;
	flex: 1 1 auto;
}

.headline-wrapper .headline-link {
	text-align: left;
}

.page-header-container > .btn {
	margin-right: 20px;
}

.page-header-container > .btn i {
	margin-right: 10px;
}

.page-header-container .page-headline-links-wrapper {
	margin-left: 30px;
}

.page-header-container .page-headline-links-wrapper .page-headline-link {
	font-size: 20px;
	color: #333333;
}

.logged-in-wrapper {
	text-align: center;
	align-items: center;
	border: 1px solid #ff4f4f;
	border-top: 0;
	border-radius: 7px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	background-color: #ff7878;
	padding: 2px 20px;
	font-size: 16px;
	font-weight: bold;
}

.logged-in-wrapper .btn {
	color: #212529;
	font-size: 16px;
	font-weight: bold;
}

.logged-in-wrapper .btn:hover {
	text-decoration: underline;
}

@media screen and (max-width: 767px) {
	.page-header-container {
		flex-direction: column;
		align-items: flex-start;
		padding: calc(var(--gap) / 2);
		padding-bottom: 5px;
	}

	.headline-wrapper {
		align-self: center;
	}

	.headline-wrapper .headline-title {
		width: calc(100vw - 30px);
		margin-bottom: 10px;
	}

	.search-list-wrapper {
		width: calc(100vw - 30px);
	}
}
