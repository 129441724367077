.account-details-form .form-label {
	display: flex;
	align-self: center;
}

@media screen and (max-width: 767px) {
	.account-details-container {
		height: calc(100vh - 150px);
	}

	.account-details-container .main-content-inner-container {
		height: calc(100vh - 150px);
	}
}
