.grid-tool-left-container {
	position: relative;
	width: 450px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* align-items: center; */
	border-right: 1px solid var(--default-border-color);
	transition: var(--default-transition);
}

.grid-tool-left-container .company-name {
	font-size: 20px;
	width: 440px;
	margin-bottom: 5px;
}

.grid-tool-left-container .created-date {
	font-size: 14px;
}

.grid-tool-content-container {
	flex: 1;
	height: 100%;
	background-color: #fbfbfb;
}

.grid-tool-content-inner-container {
	width: 1000px;
	padding: 0;
}

.grid-tool-content-inner-container .area-radius-value {
	width: 100px;
}

.grid-tool-buttons-container {
	padding: 15px;
}

.grid-tool-content-container #tabReportContent > .tab-pane {
	height: calc(100vh - 94px);
}

.grid-tool-content-container .map-wrapper {
	height: calc(100vh - 50px);
}

.grid-tool-content-container .map-wrapper.no-transition .ranking-bubble,
.grid-tool-content-container .map-wrapper.no-transition .ranking-bubble .details {
	transition: none !important;
	animation: none !important;
}

.grid-tool-content-container .info-wrapper {
	display: flex;
}

.grid-tool-content-container .info-wrapper .info-item {
	padding: 5px 15px 1px;
	/* border-right: 1px solid #dee2e6; */
}

.grid-tool-content-container .info-wrapper .info-item-separator {
	margin-top: 3px;
}

/* audit */
#tabAuditDataContent {
	height: calc(100vh - 225px);
	overflow: auto;
}

#tabAuditDataContent .tab-pane {
	position: relative;
}

.audit-section {
	margin-bottom: 20px;
}

.audit-section-title {
	font-size: 18px;
	margin-bottom: 10px;
}

.audit-item {
	margin-bottom: 5px;
}

.audit-item .form-label {
	font-size: 15px;
}

.audit-item-label {
	width: 120px;
}

.audit-update-wrapper {
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 2;
}

.audit-data-container .audit-table-wrapper {
	height: calc(100vh - 185px);
	overflow: auto;
}

table.audit-table th.description,
table.audit-table td.description {
	max-width: 500px;
}

table.audit-table th.business,
table.audit-table td.business {
	max-width: 200px;
	text-align: center;
}

table.audit-table td.separator-row {
	background-color: #ebebeb;
}

.competitors-wrapper {
	height: calc(100vh - 175px);
	overflow: auto;
}

.competitors-wrapper .competitor-item .competitor-name {
	font-size: 20px;
}

.competitors-wrapper .competitor-item .competitor-label,
.competitors-wrapper .competitor-item .competitor-value {
	font-size: 15px;
}

.competitors-wrapper .competitor-item .competitor-label {
	display: inline-block;
	width: 140px;
	margin-right: 20px;
}

.btn-json-output span {
	color: #1da524;
	font-weight: bold;
}

.btn-json-output:hover {
	text-decoration: underline;
}

.json-output-no-value {
	color: #d13139;
}

.json-output-object-item {
	display: flex;
}

.json-output-object-item-label {
	text-align: left;
	margin-bottom: 0;
	width: 130px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.json-output-object-item-value {
	width: 100%;
	text-align: left;
	margin-bottom: 0;
	/* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */
}

.json-output-object-item .json-output-object-item-value {
	width: 90%;
}

.json-output-object-item-value i.fa-star {
	font-size: 13px;
	color: #ffbb29;
}

.review-answer {
	margin: 10px 0 5px 20px;
	padding-left: 10px;
	width: calc(100% - 20px);
	font-size: 14px;
}

.json-output-container .small-size {
	font-size: 13px;
}

.qa-answers-wrapper {
	margin: 10px 0 5px 20px;
	padding-left: 10px;
	width: calc(100% - 20px);
	font-size: 14px;
}

#carouselAuditPhotos .carousel-item {
	height: calc(100vh - 225px);
}

#carouselAuditPhotos .carousel-item img {
	position: absolute;
	object-fit: cover;
	background-size: cover;
	top: 0;
	left: 0;
	height: calc(100vh - 225px);
}

@media screen and (max-width: 1200px) {
	.grid-tool-content-inner-container {
		width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.grid-tool-content-container {
		width: 100%;
		height: calc(100% - 75px);
	}
}
