@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swa');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
	font-family: 'Gilroy-Bold';
	src: local('Gilroy-Bold'), url(./fonts/gilroy/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'Gilroy-SemiBold';
	src: local('Gilroy-SemiBold'), url(./fonts/gilroy/Gilroy-SemiBold.ttf) format('truetype');
}

@font-face {
	font-family: 'Gilroy-Medium';
	src: local('Gilroy-Medium'), url(./fonts/gilroy/Gilroy-Medium.ttf) format('truetype');
}

:root {
	--gap: 30px;
	--primary-color: #1444f5;
	--primary-border-color: #ced9fc;
	--default-background-color: #f9fafb;
	--alt-background-color: rgba(24, 119, 242, 0.08);
	--modal-dialog-background-color: white;
	--modal-buttons-shaded-color: #f5f6fa;
	--modal-buttons-shaded-border-color: #ddd;
	/* --default-font: "DM Sans"; */
	--default-font: "Comfortaa";
	--default-font-bold: "Comfortaa";
	--default-font-color: #405264;
	--default-font-size: 1rem;
	--main-app-color: #208bb9;
	--default-margin: 30px;
	--default-border-color: #ddd;
	--default-border-radius: 4px;
	--default-transition: all .3s ease-out;
	--modal-dialog-background-color: white;
	--modal-buttons-shaded-color: #f5f6fa;
	--modal-buttons-shaded-border-color: #ddd;
	--list-selected-row: #e2e3e5;
}

body {
	font-family: var(--default-font);
	font-weight: 500;
	font-size: var(--default-font-size);
	background-color: var(--default-background-color);
}

body.body-full-screen {
	overflow: hidden;
}

body.gray-background {
	background-color: #f6f6f6;
}

* {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

hr {
	border-top: 1px solid var(--default-border-color);
	opacity: 1;
}

.app {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
}

.main-content-container {
	height: calc(100vh - 85px);
	margin: 20px 0;
	border-radius: 10px;
}

.main-content-inner-container {
	height: calc(100vh - 87px);
	overflow: hidden auto;
	background-color: #ffffff;
	border: 1px solid #f1f3f4;
	border-radius: 10px;
	box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), 0 5px 15px 0 rgba(0, 0, 0, .12);
}

.main-content-inner-container table tr {
	vertical-align: middle;
}

.main-content-inner-container table tr:hover td {
	background-color: rgba(150, 159, 172, 0.16);
}

.main-content-inner-container table th {
	border-bottom: 1px solid #f1f3f4;
	padding: 15px 10px;
	background-color: var(--alt-background-color);
	color: #2d86cf;
	font-weight: 500;
}

.main-content-inner-container table td {
	border-bottom: 1px solid #f1f3f4;
	padding: 15px 10px;
	background-color: #ffffff;
}

.main-content-inner-container table th.date,
.main-content-inner-container table td.date {
	width: 140px;
	text-align: start;
}

.main-content-inner-container table th.date-time,
.main-content-inner-container table td.date-time {
	width: 200px;
	text-align: start;
}

.main-content-inner-container table th.status,
.main-content-inner-container table td.status {
	width: 150px;
	text-align: start;
}

.main-content-inner-container table td.error i {
	color: #ca1e1e;
}

.main-content-inner-container table th.setting,
.main-content-inner-container table td.setting {
	width: 140px;
	text-align: center;
}

.main-content-inner-container table th.count,
.main-content-inner-container table td.count {
	width: 100px;
	text-align: center;
}

.main-content-inner-container table th.campaign-name,
.main-content-inner-container table td.campaign-name {
	max-width: 400px;
}

.main-content-inner-container table th.url,
.main-content-inner-container table td.url,
.main-content-inner-container table td.url a {
	width: 500px;
}

.main-content-inner-container table td.task-action {
	width: auto;
	max-width: 150px;
}

.main-content-inner-container table td.task-action .btn-link {
	padding-left: 5px;
	padding-right: 5px;
	text-decoration: underline;
}

.main-content-inner-container table td .action-button-placeholder {
	display: inline-block;
	width: 43px;
}

.btn.btn-link {
	text-decoration: none;
}

.btn i {
	margin-right: 5px;
}

.buttons-container {
	margin-top: var(--gap);
}

.buttons-container .btn {
	margin-right: 10px;
}

.buttons-container .btn:last-child {
	margin-right: 0;
}

.app-link-button {
	color: var(--default-font-color);
	text-decoration: underline !important;
	padding: 0;
}

.app-link-button i {
	color: var(--default-font-color);
}

/* tabs */
.nav-tabs .nav-link {
	color: #495057;
	background-color: #f1f1f1;
	border: 1px solid #dee2e6;
}

.nav-tabs .nav-link.active {
	border-bottom: 1px solid #dee2e6;
}

.tab-content {
	border-top: 0 !important;
}

/* .full-screen {
	position: fixed;
	top: 68px;
	left: 0;
	bottom: 0;
	right: 0;
	height: calc(100% - 68px);
	z-index: 999;
	background-color: white;
} */

.form-label {
	margin-bottom: 0.25rem;
}

/* .form-control {
	padding: .75rem 1rem;
} */

table.items-list {
	margin-bottom: 0;
	/* height: 100%; */
}

table.items-list thead {
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: white;
}

table.items-list tfoot {
	position: sticky;
	bottom: 0;
	z-index: 1;
	background-color: white;
}

table.items-list tfoot th {
	font-weight: normal;
}

table.items-list tr:last-child th,
table.items-list tr:last-child td,
table.items-list tfoot tr:last-child th,
table.items-list tfoot tr:last-child td {
	border-bottom: 0;
}

table.items-list td {
	font-size: var(--default-font-size);
	color: var(--default-font-color);
}

table.items-list td .btn {
	color: var(--default-font-color);
	padding: 5px 10px;
}

table.items-list td .btn i {
	font-size: 16px;
}

.search-list-wrapper {
	display: flex;
	position: relative;
	width: 300px;
	text-align: right;
}

.search-list-wrapper input {
	padding-left: 30px;
	border-radius: 10px;
}

.search-list-wrapper .btn-search {
	position: absolute;
	left: 0;
	color: var(--default-font-color);
}

.table-search-filter {
	position: sticky;
	top: 0;
	z-index: 1;
	background: white;
}

.search-list-wrapper input.has-value,
.table-search-filter input.has-value {
	background-color: #f8dede;
	border: 1px solid #ff0000;
	font-weight: bold;
}

.shadow-large {
	box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), 0 5px 15px 0 rgba(0, 0, 0, .12);
}

.loading-map {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(255 255 255 / 60%);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

/* time picker */
.MuiInputBase-input {
	padding: 8px !important;
	color: var(--default-font-color) !important;
}

#chat-widget-container {
	z-index: 999 !important;
	visibility: visible !important;
	opacity: 1 !important;
	margin-right: 10px;
}

@media screen and (max-width: 767px) {
	:root {
		--default-font-size: 0.9rem;
	}

	body {
		font-size: var(--default-font-size);
	}

	.main-content-container {
		margin: calc(var(--default-margin) / 2);
	}

	.main-content-inner-container table td {
		padding: 10px 8px;
	}

	.main-content-inner-container table th.campaign-name,
	.main-content-inner-container table td.campaign-name {
		max-width: 60vw;
	}

	.btn {
		font-size: var(--default-font-size);
	}

	.btn i {
		font-size: 1rem;
	}

	.main-content-inner-container table td.task-action .btn-link {
		padding-left: 2px;
		padding-right: 2px;
		font-size: var(--default-font-size);
	}

	.main-content-inner-container table td.task-action {
		max-width: 160px;
	}

	.nav-tabs .nav-link {
		font-size: 0.8rem;
		padding: 0.5rem 0.5rem;
	}

	.form-control {
		font-size: var(--default-font-size);
	}
}
