.grid-report-keywords-v5 {
	position: sticky;
	z-index: 3;
	top: 44px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	gap: 30px;
	padding: 10px 0 20px 0;
	background: white;
}

.grid-report-keywords-v5.is-full-screen {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 68px;
	z-index: 1000;
}

.grid-report-keywords-v5 .grid-report-keyword {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 20px;
	background-color: #ddd;
	border: 1px solid #f2f2f2;
	border-radius: 5px;
	cursor: pointer;
}

.grid-report-keywords-v5 .grid-report-keyword.active {
	color: var(--primary-color);
	border-color: var(--primary-color);
}

.grid-report-keywords-v5 select {
	border-radius: 5px;
}

.grid-report-keywords-v5 .nav-pills .nav-link {
	text-transform: capitalize;
}

.nav.nav-pills.disabled {
	pointer-events: none;
}

@media screen and (max-width: 767px) {
	.grid-report-keywords-v5 {
		top: 60px;
	}
}
