.campaign-details-container .main-content-inner-container {
	height: calc(100vh - 190px);
}

@media screen and (max-width: 767px) {
	.campaign-details-container {
		height: calc(100vh - 180px);
	}

	.campaign-details-container .main-content-inner-container {
		height: calc(100vh - 220px);
	}

	.small-btn {
		font-size: 0.75rem;
		padding: 0.375rem 0.5rem;
	}
}
