.login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.login-wrapper {
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	overflow: hidden;
	box-shadow: rgba(145, 158, 171, 0.08) 0px 0px 2px 0px, rgba(145, 158, 171, 0.08) 0px 12px 24px -4px;
	border-radius: 16px;
	position: relative;
	z-index: 0;
	padding: 40px;
	width: 100%;
	max-width: 420px;
	background-color: #ffffff;
}
