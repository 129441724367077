.app-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	overflow: hidden;
}

/* scroll bar */
::-webkit-scrollbar {
	width: 4px;
	border-radius: 50%;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: #bbbbbb;
}

::-webkit-scrollbar-thumb:hover {
	background: #777777;
}

@media screen and (max-width: 767px) {
	.app-container {
		flex-direction: column;
	}
}
