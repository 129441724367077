table.grid-reports-list td.task-action {
	width: 170px;
}

@media screen and (max-width: 767px) {
	.reports-list-container {
		height: calc(100vh - 155px);
	}

	.reports-list-container .main-content-inner-container {
		height: calc(100vh - 195px);
	}
}
