table.items-list tfoot.list-footer th {
	padding: 0;
}

table.items-list tfoot.list-footer .items-list-count {
	display: flex;
	align-items: center;
	padding: 15px;
}

table.items-list tfoot.list-footer .btn-refresh-list {
	color: #2d86cf;
	text-decoration: underline;
	margin-left: 25px;
	padding: 0;
	font-family: var(--default-font);
	font-size: var(--default-font-size);
}
