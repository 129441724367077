.countdown-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	align-items: center;
	margin: 0.5rem;
}

.countdown-container > form {
	padding: 0.5rem;
}

.countdown-container > form > label {
	margin-right: 1rem;
}

.show-counter {
	padding: 0.75rem;
}

.show-counter .countdown-link {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-top: 0.5rem;
}

.show-counter .countdown {
	line-height: 1.25rem;
	min-width: 70px;
	margin: 0 10px;
}

.show-counter .countdown > p {
	margin: 0;
	padding: 25px 15px;
	color: #cc0000;
	border: 1px solid #cc0000;
	border-radius: 5px;
	font-size: 24px;
}

.show-counter .countdown > span {
	text-transform: uppercase;
	font-size: 0.75rem;
	line-height: 2rem;
	color: #cc0000;
	font-weight: normal;
}

@media only screen and (max-width: 500px) {
	.show-counter .countdown > span {
		font-size: 1rem;
		line-height: 1rem;
	}

	.show-counter .countdown {
		margin: 0 5px;
	}

	.show-counter .countdown-link {
		font-size: 1.5rem;
	}
}
