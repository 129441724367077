.campaigns-list-container .main-content-inner-container {
	height: calc(100vh - 145px);
}

table.default-offers-list th.select-offer,
table.default-offers-list td.select-offer {
	width: 120px;
	text-align: center;
	vertical-align: middle;
}

table.default-offers-list td .offer-description {
	font-size: 14px;
}

table.default-offers-list td.price {
	width: 80px;
	text-align: center;
	vertical-align: middle;
	font-size: 18px;
	border-left: 0px solid #ffffff;
}

@media screen and (max-width: 767px) {
	.campaigns-list-container {
		height: calc(100vh - 155px);
	}

	.campaigns-list-container .main-content-inner-container {
		height: calc(100vh - 195px);
	}
}
