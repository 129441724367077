.requirements-approved-container {
	padding: 20px;
	padding-bottom: 0;
	font-family: Poppins, sans-serif;
	color: #444444;
}

.requirements-approved-container .container {
	padding: 0 20px;
}

.requirements-approved-container .requirements-approved-container-inner {
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border-bottom: 0;
	border-radius: 5px 5px 0 0;
	padding: 40px;
	box-shadow: 0px 0px 7px 0px #dddddd;
}

.requirements-approved-container .requirements-approved-container-inner .title-wrapper {
	padding-bottom: 20px;
}

.requirements-approved-container .requirements-approved-container-inner .title {
	font-weight: 700;
	font-size: 32px;
	color: #0b305b;
}

.requirements-approved-container .requirements-approved-container-inner p {
	font-size: 20px;
	line-height: 1.5em;
}

.requirements-approved-container .requirements-approved-container-inner a {
	color: #2b7bb9;
	text-decoration: none;
}

.requirements-approved-container .requirements-approved-container-inner pre {
	font-family: Poppins, sans-serif;
	color: #444444;
	font-size: 20px;
	line-height: 1.4em;
	text-wrap: wrap;
}

.requirements-approved-container .requirements-approved-container-inner .campaign-info-wrapper {
	background-color: #f6f5f5;
	border: 1px solid #ebebeb;
	border-radius: 5px;
	padding: 20px 30px;
	margin-bottom: 30px;
}

.requirements-approved-container .requirements-approved-container-inner .messages-wrapper {
	background-color: #f6f5f5;
	border: 1px solid #ebebeb;
	border-radius: 5px;
	padding: 20px 30px;
}

.requirements-approved-container .requirements-approved-container-inner .form-label {
	min-width: 150px;
}

.requirements-approved-container .requirements-approved-container-inner .keyword-number {
	display: inline-block;
	width: 30px;
}

.message-wrapper {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 10px;
}

.message-wrapper:last-child {
	margin-bottom: 0px;
}

.message-wrapper.outbound {
	justify-content: flex-end;
}

.message-wrapper .message-details {
	position: relative;
	padding: 5px 10px 25px 10px;
	border-radius: 10px;
	max-width: 80%;
}

.message-wrapper.outbound .message-details {
	background-color: #daeefc;
}

.message-wrapper.inbound .message-details {
	background-color: #eeeeee;
}

.message-wrapper .message-details pre {
	font-family: inherit;
	font-size: 16px;
	padding: 3px;
	text-wrap: wrap;
}

.message-wrapper .message-details .message-date {
	position: absolute;
	padding: 0;
	font-size: 12px;
	bottom: 3px;
	right: 12px;
}

@media screen and (min-width: 992px) {
	.requirements-approved-container .container {
		width: 800px;
	}
}

@media screen and (max-width: 768px) {
	.requirements-approved-container {
		padding: 10px;
		padding-bottom: 80px;
	}

	.requirements-approved-container .container {
		padding: 0;
	}

	.requirements-approved-container .requirements-approved-container-inner {
		padding: 20px;
	}

	.requirements-approved-container .requirements-approved-container-inner .title-wrapper {
		padding-bottom: 30px;
	}
}
